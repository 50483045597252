import * as React from "react";
import { useState, useCallback } from "react";
import { IWrappableDataContext } from "../data.context";
import { Game } from "@models/index";
import useApi from "@app/hooks/api.hook";

const GAMES_LOCAL_STORAGE_KEY = "GAMES";

export interface IGamesDataContext extends IWrappableDataContext{
  getGamesAsync: (forceRefresh: boolean) => Promise<Game[]>;
  getGameAsync: (gameId: number, forceRefresh: boolean) => Promise<Game>;
}


export const GamesDataContext = React.createContext<IGamesDataContext>({
  clear: undefined,
  getGamesAsync: undefined,
  getGameAsync: undefined
});

const GamesDataContextProvider: React.FC = props => {
  // States
  const [games, setGames] = useState<Game[]>(null);

  // Hooks
  const api = useApi();



  const getGamesAsync = useCallback(async (forceRefresh: boolean) : Promise<Game[]> => {
    if (games == undefined || forceRefresh) {
      var games_localStorage: Game[] = JSON.parse(
        localStorage.getItem(GAMES_LOCAL_STORAGE_KEY)
      );
      if (games_localStorage !== null) {
        setGames(games_localStorage);
        return games_localStorage;
      } else {
        var fetchGames = await api.getGamesAsync();
        setGames(fetchGames);
        return fetchGames;
      }
    } else
    {
      return games;
    }
  }, [setGames, JSON.parse, localStorage.getItem, api.getGamesAsync]);




  const getGameAsync = useCallback(async (gameId: number, forceRefresh: boolean) : Promise<Game> => {
    if (games == undefined || forceRefresh) {
      var games_localStorage: Game[] = JSON.parse(
        localStorage.getItem(GAMES_LOCAL_STORAGE_KEY)
      );
      if (games_localStorage !== null) {
        setGames(games_localStorage);
        return games_localStorage.find(g => g.id == gameId);
      } else {
        var fetchGame = await api.getGameAsync(gameId);
        setGames([...games, fetchGame]);
        return fetchGame;
      }
    }
    else
    {
      return games.find(g => g.id == gameId);
    }
  }, [setGames, JSON.parse, localStorage.getItem, api.getGameAsync]);




  const clear = useCallback(() => {
    setGames(null);
  }, [setGames]);




  const GamesContextValue: IGamesDataContext = {
    clear,
    getGamesAsync,
    getGameAsync
  };

  return (
    <GamesDataContext.Provider value={GamesContextValue}>
      {props.children}
    </GamesDataContext.Provider>
  );
};

export default GamesDataContextProvider;
