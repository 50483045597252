import "./homeScreenshots.scss";
import * as React from "react";
import { Feed, HorizontalScroll, ContentCard } from "@components/index";
import { Screenshot, Game } from "@models/index";
import * as moment from "moment";
import SearchIcon from "@material-ui/icons/Search";
import { useLocation, useHistory } from "react-router-dom";
import { GamesDataContext } from "@app/contexts/data/game.data";
import { useContext, useState, useEffect, useCallback } from "react";
import { ScreenshotsDataContext } from "@app/contexts/data/screenshot.data";

export interface IHomeScreenshotsFilters {
  title: string | undefined;
  gameId: number | undefined;
  tagsId: number[] | undefined; //TODO_V2
  publication_date_rage:
    | {
        //TODO_V2
        from: moment.Moment | undefined;
        to: moment.Moment | undefined;
      }
    | undefined;
  gamersId: number[] | undefined; //TODO_V2
  note: number | undefined; //TODO_V2
}

export const HomeScreenshotsPage = () => {
  const NUMBER_OF_LATEST_SCREENSHOTS = 10;
  const location = useLocation();
  const history = useHistory();

  const screenshotsContext = useContext(ScreenshotsDataContext);
  const gamesContext = useContext(GamesDataContext);

  const [games, setGames] = useState<Game[]>();
  const [screenshots, setScreenshots] = useState<Screenshot[]>();
  const [filteredScreenshots, setFilteredScreenshots] = useState<Screenshot[]>([]);
  const [filters, setFilters] = useState<IHomeScreenshotsFilters>({gameId: undefined, gamersId: undefined, note: undefined, publication_date_rage: undefined, tagsId: undefined, title: undefined});

  useEffect(() => {
    screenshotsContext.getScreenshotsAsync(false).then(screenshots => setScreenshots(screenshots));
    gamesContext.getGamesAsync(false).then(games => setGames(games));

    var path = location.pathname;
    if (path.startsWith("/screenshots/game/")) {
      setFilters({...filters, gameId: parseInt(path.replace("/screenshots/game/", ""))});
    }
  }, []);

  useEffect(() => {
    setFilteredScreenshots(screenshots?.filter(s => s.title
        .toLocaleLowerCase()
        .includes(filters.title ? filters.title.toLocaleLowerCase() : "")
      )
      .filter(r => !filters.gameId || r.gameId === filters.gameId) || []
    );
  }, [screenshots, filters, setFilteredScreenshots]);


  const handleSearchTitleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setFilters({...filters, title: e.target.value.toLocaleLowerCase()});
  }, [setFilters, filters]);

  const handleGameSelectorChange = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
    setFilters({...filters, gameId: parseInt(e.target.value)});
  }, [setFilters, filters]);


  const openRecord = useCallback((selectedItemKey: string | number | null): void => {
    var screenshot = screenshots?.find(
      s => s.id.toString() === selectedItemKey.toString()
    );
    if (screenshot) {
      history.push(`/screenshots/${screenshot.id}`);
    }
  }, [screenshots, history]);

  const renderFilters = useCallback(() => 
    <div id="screenshot-filters-container">
      <form>
        {/* //TODO_V2 disable refresh when "Enter" is pressed */}
        <div id="custom-search-input">
          <SearchIcon />
          <input
            type="text"
            id="screenshot_title"
            name="screenshot_title"
            placeholder="Search title"
            onChange={e => {
              handleSearchTitleChange(e);
            }}
          />
        </div>
        <div id="custom-search-game-dropdown">
          <select
            id="gameId-selector"
            name="gameId"
            onChange={e => {
              handleGameSelectorChange(e);
            }}
            value={filters.gameId}
          >
            <option value={undefined}>All games</option>
            {games?.map(g => (
              <option key={g.id} value={g.id}>
                {g.name}
              </option>
            ))}
          </select>
        </div>
      </form>
    </div>
  , [games, filters.gameId]);

  return (
    <Feed>
      <div className="content-section">
        <div className="content-section-title">LATEST</div>
        <HorizontalScroll
          contents={screenshots?.slice(
            0,
            NUMBER_OF_LATEST_SCREENSHOTS
          )}
          selected={""}
          onElementSelect={openRecord}
        />
      </div>
      <hr />
      <div className="content-section">
        <div className="content-section-title">ALL</div>
        {renderFilters()}
        <div className="content-card-wrapper">
          {filteredScreenshots.map(s =>
            <ContentCard
              key={s.id}
              content={s}
              selected={false}
              onClick={() => openRecord(s.id)}
            /> 
          )}
        </div>
      </div>
    </Feed>
  );
}