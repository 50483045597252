import * as React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import ProtectedRoute, { ProtectedRouteProps } from "./protectedRoute";

import { ConfigHelper } from "@app/helpers";
import { HomePage } from "@pages/home/home.component";
import { MePage } from "@pages/me/me.component";
import { PlatformPage } from "@pages/platform/platform.component";
import { HomeRecordsPage } from "@pages/records/homeRecords/homeRecordsPage.component";
import { NavigationBar } from "@components/navigationBar/navigationBar.component";
import { RecordPage } from "@app/pages/records/record/recordPage.component";
import { HomeScreenshotsPage } from "@app/pages/screenshots/homeScreenshots/homeScreenshotsPage.component";
import { ScreenshotPage } from "@app/pages/screenshots/screenshot/ScreenshotPage.component";
import { LoginPage } from "@app/pages/auth/login/login.component";
import { LoginCallbackPage } from "@app/pages/auth/loginCallback/loginCallback.component";
import { RegisterPage } from "@app/pages/auth/register/register.component";
import { RegisterCallbackPage } from "@app/pages/auth/registerCallback/registerCallback.component";
import { LogoutPage } from "@app/pages/auth/logout/logout.component";
import { PreparingSessionPage } from "@app/pages/auth/preparingSession/preparingSession.component";


export const LOGIN_PATH = "/login";
export const ACCESS_RESTRICTED_PATH = "/access-unauthorized";

export default function Routing() {  
  const authContext = ConfigHelper.useAuthContext();

  const authenticatedRouteProps: ProtectedRouteProps = {
    mustBeAuthenticated: true,
    mustBeAdmin: false
  };

  const adminRouteProps: ProtectedRouteProps = {
    mustBeAuthenticated: true,
    mustBeAdmin: true
  };

  return (
    <React.Fragment>
      {!authContext.isSilentlyLogin ?
      <React.Fragment>
          <NavigationBar />
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route path="/me" component={MePage}/>
            <Route path="/platforms" component={PlatformPage}/>
            <Route path="/clips/game" component={HomeRecordsPage}/>
            <Route exact path="/clips" component={HomeRecordsPage}/>
            <Route path="/clips" component={RecordPage}/>
            <Route path="/screenshots/game" component={HomeScreenshotsPage}/>
            <Route exact path="/screenshots" component={HomeScreenshotsPage}/>
            <Route path="/screenshots" component={ScreenshotPage}/>
            <Route path="/login" component={LoginPage}/>
            <Route path="/auth/callback/login" component={LoginCallbackPage}/>
            <Route path="/register" component={RegisterPage}/>
            <Route path="/auth/callback/register" component={RegisterCallbackPage}/>
            <Route path="/logout" component={LogoutPage}/>
            <Redirect from="*" to="/" />
          </Switch>
        </React.Fragment>
      : <PreparingSessionPage/>}
    </React.Fragment>
  );
}
