import * as React from "react";
import "./navigationBar.scss";
import { Nav, Navbar } from "react-bootstrap";
import { Link } from 'react-router-dom'
import { ConfigHelper } from '@app/helpers';


export const NavigationBar = () => {
  const authContext = ConfigHelper.useAuthContext();

  return (
    <div id="navigationBar">
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="dark"
        variant="dark"
        fixed="top"
      >
        <img
          src="/assets/images/logo_white.png"
          width="38"
          height="30"
          className="d-inline-block align-top"
          alt="Logo"
        />
        <Navbar.Brand href="/">Sneakyearth</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
            {/*<Nav.Link href="/games">Games</Nav.Link>*/}
            {/*<NavDropdown title="Clips" id="collasible-nav-dropdown" >
              <NavDropdown.Item href="/clips/game/4">
                Rainbow Six Siege
              </NavDropdown.Item>
              <NavDropdown.Item href="/clips/game/1">
                Battlefield 1
              </NavDropdown.Item>
              <NavDropdown.Item href="/clips/game/5">
                Star Citizen
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/clips">More...</NavDropdown.Item>
            </NavDropdown>*/}
            <Nav.Link as={Link} to="/clips">Clips</Nav.Link>
            <Nav.Link as={Link} to="/screenshots">Screens</Nav.Link>
            <Nav.Link as={Link} to="/platforms">Platforms</Nav.Link>
          </Nav>
          <Nav>
            {/*<div id="languageSelectorContainer">
              <img
                className="languageSelector"
                src="/flags/fr_flag.png"
                height="15px"
                width="22px"
                alt="French"
              />
              <img
                className="languageSelector"
                src="/flags/de_flag.png"
                height="15px"
                width="22px"
                alt="German"
              />
              <img
                className="languageSelector"
                src="/flags/en_flag.png"
                height="15px"
                width="22px"
                alt="English"
              />
            </div>*/}
            {authContext.isSessionReady ? 
              <React.Fragment>
                <span id="welcomeMessage" className="navbar-dark">Hi {authContext.currentUser.pseudo || authContext.currentUser.email}!</span>
                <Nav.Link as={Link} to="/logout">Log out</Nav.Link>
              </React.Fragment> 
              : 
              <React.Fragment>
                <Nav.Link as={Link} to="/login">Log in</Nav.Link>
                <Nav.Link as={Link} to="/register">Register</Nav.Link>
              </React.Fragment>
            }
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}
