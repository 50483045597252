import * as React from "react";
import {BrowserRouter as Router } from "react-router-dom";
import { AuthContextProvider } from "@haberkorn/react-auth";

import { DataContextWrapper } from "@contexts/index";
import Routing from "./routing/routing";
import { ConfigHelper } from '@app/helpers';
import { RootUrlNames } from "./helpers/config.helper";

export const App = () => {
  return (
    <DataContextWrapper>
      <Router>
        <AuthContextProvider authApiRootUrl={ConfigHelper.getRootUrl(RootUrlNames.BACKEND_API)} getAuthProvider={ConfigHelper.getAuthProvider}>
          <Routing />
        </AuthContextProvider>
      </Router>
    </DataContextWrapper>
  );
};
