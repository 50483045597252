import "./platformOverviewCard.scss";
import * as React from "react";
import { PlatformAccount, GamingPlatform } from "@models/index";
import { ActivityEnum } from "@app/enums/activity";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { useState, useCallback } from "react";

export interface IPlatformOverviewCardProperties{
  platformAccount: PlatformAccount | undefined,
  gamingPlatform: GamingPlatform
}

export const PlatformOverviewCard: React.FC<IPlatformOverviewCardProperties> = (props) => {
  const [expanded, setExpanded] = useState(false);


  const toogleExpand = useCallback(() => {
    setExpanded(!expanded);
  }, [setExpanded, expanded]);


  const renderPlatformActivity = useCallback(() => {
    var className = "platformActivity badge ";
    var textContent = "";

    if (!props.platformAccount) {
      return null;
    }
    switch (props.platformAccount.activityId) {
      case ActivityEnum.VeryActive:
        className += "green";
        textContent = "Very active";
        break;

      case ActivityEnum.Active:
        className += "darkGreen";
        textContent = "Active";
        break;

      case ActivityEnum.Occasionally:
        className += "orange";
        textContent = "Occasionally";
        break;

      case ActivityEnum.Rarely:
        className += "darkOrange";
        textContent = "Rarely active";
        break;

      case ActivityEnum.NotAnymore:
        className += "red";
        textContent = "Not active anymore";
        break;
    }

    return (
        <div className={className}>{textContent}</div>
    );
  }, [props.platformAccount]);


  const renderExpanded = useCallback(() => {
    return (
      <React.Fragment>
        {/* Platform description */}
        <div className="platformDescription">
          <span className="descriptionLabel">Platform</span>
          <br />
          {props.gamingPlatform.description}
        </div>

        {/* Link to platform */}
        {props.gamingPlatform.url ? (
          <div className="platformLinkContainer">
            <a
              className="platformLink"
              href={props.gamingPlatform.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              Discover the platform
            </a>
          </div>
        ) : null}

        {/* Account description */}
        {props.platformAccount ? (
          <div className="accountDescription">
            <span className="descriptionLabel">Account</span>
            <br />
            {props.platformAccount.description}
          </div>
        ) : null}

        {/* Link to account */}
        {props.platformAccount && props.platformAccount.url ? (
          <div className="accountLinkContainer">
            <a
              className="accountLink"
              href={props.platformAccount.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              Follow or join me
            </a>
          </div>
        ) : null}
      </React.Fragment>
    );
  }, []);


  return (
    <div
      className={
        "platformOverviewCard contentCard " + (expanded ? "expanded" : "notExpanded")
      }
      key={props.gamingPlatform.id}
    >
      <div className="contentCardHeader" onClick={toogleExpand}>
        {expanded ? (
          <FontAwesomeIcon
            icon={faCaretDown}
            className="toogleExpandedIcon"
            fixedWidth
          />
        ) : (
          <FontAwesomeIcon
            icon={faCaretRight}
            className="toogleExpandedIcon"
            fixedWidth
          />
        )}
        <img
          src={props.gamingPlatform.imageUrl}
          className="platformIcon"
          alt={props.gamingPlatform.name + " icon"}
        ></img>
        <div className="platformIdentityContainer">
          <span className="platformName">
            {props.gamingPlatform.name}
          </span>
          <br />

          {/* Account name and tag */}
          {props.platformAccount
            ? (
              <React.Fragment>
                <span className="userName">
                  {props.platformAccount.userName}
                </span>
                {props.platformAccount.userTag ? (
                  <span className="userTag">
                    #{props.platformAccount.userTag}
                  </span>
                ) : null}
              </React.Fragment>
            )
            : null}
        </div>
        <div className="platformActivityContainer">
          {renderPlatformActivity()}
        </div>
      </div>
      {expanded ? renderExpanded() : null}
    </div>
  );
}
