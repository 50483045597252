import * as React from "react";
import { useState, useCallback } from "react";
import { IWrappableDataContext } from "../data.context";
import { GamingPlatform } from "@models/index";
import useApi from "@app/hooks/api.hook";

const GAMING_PLATFORMS_LOCAL_STORAGE_KEY = "GAMING_PLATFORMS";

export interface IGamingPlatformsDataContext extends IWrappableDataContext{
  getGamingPlatformsAsync: (forceRefresh: boolean) => Promise<GamingPlatform[]>;
}


export const GamingPlatformsDataContext = React.createContext<IGamingPlatformsDataContext>({
  clear: undefined,
  getGamingPlatformsAsync: undefined
});

const GamingPlatformsDataContextProvider: React.FC = props => {
  // States
  const [gamingPlatforms, setGamingPlatforms] = useState<GamingPlatform[]>(null);

  // Hooks
  const api = useApi();



  const getGamingPlatformsAsync = useCallback(async (forceRefresh: boolean) : Promise<GamingPlatform[]> => {
    if (gamingPlatforms == undefined || forceRefresh) {
      var gamingPlatforms_localStorage: GamingPlatform[] = JSON.parse(
        localStorage.getItem(GAMING_PLATFORMS_LOCAL_STORAGE_KEY)
      );
      if (gamingPlatforms_localStorage !== null) {
        setGamingPlatforms(gamingPlatforms_localStorage);
        return gamingPlatforms_localStorage;
      } else {
        var fetchGamingPlatforms = await api.getGamingPlatformsAsync();
        setGamingPlatforms(fetchGamingPlatforms);
        return fetchGamingPlatforms;
      }
    } else
    {
      return gamingPlatforms;
    }
  }, [setGamingPlatforms, JSON.parse, localStorage.getItem, api.getGamingPlatformsAsync]);


  const clear = useCallback(() => {
    setGamingPlatforms(null);
  }, [setGamingPlatforms]);




  const GamingPlatformsContextValue: IGamingPlatformsDataContext = {
    clear,
    getGamingPlatformsAsync
  };

  return (
    <GamingPlatformsDataContext.Provider value={GamingPlatformsContextValue}>
      {props.children}
    </GamingPlatformsDataContext.Provider>
  );
};

export default GamingPlatformsDataContextProvider;
