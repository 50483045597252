import "./record.scss";
import * as React from "react";
import { Feed } from "@components/index";
import { useLocation } from "react-router-dom";
import { Game, Record } from "@models/index";
import { useState, useEffect } from "react";
import useApi from "@app/hooks/api.hook";

export const RecordPage = () => {
  const location = useLocation();
  const api = useApi();
  
  const [record, setRecord] = useState<Record>(undefined);
  const [game, setGame] = useState<Game>(undefined);

  useEffect(() => {
    var recordId = "";
    var path = location.pathname;

    if (path.startsWith("/clips/")) {
      recordId = path.replace("/clips/", "");
    }

    async function getData() {
      var record = await api.getRecordAsync(parseInt(recordId));
      var game = await api.getGameAsync(record.gameId);

      setRecord(record);
      setGame(game);
    }

    getData();
  }, [location.pathname, api.getRecordAsync, api.getGameAsync]);

  return (
    <Feed>
      {record ? (
        <React.Fragment>
          <div className="record-title">{record.title}</div>
          <div className="record-video-container">
            <iframe
              className="record-video"
              title={record.title}
              src={record.embeddedUrl}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </React.Fragment>
      ) : null}
    </Feed>
  );
}
