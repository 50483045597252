import * as React from "react";
import GamingPlatformsDataContextProvider from "./data/gamingPlatform.data";
import PlatformAccountsDataContextProvider from "./data/platformAccount.data";
import GamesDataContextProvider from "./data/game.data";
import RecordsDataContextProvider from "./data/record.data";
import DataContextProvider from "./data.context";
import ScreenshotsDataContextProvider from "./data/screenshot.data";

export const DataContextWrapper: React.FC = props => {
  return (
    <ScreenshotsDataContextProvider>
      <RecordsDataContextProvider>
        <GamesDataContextProvider>
          <PlatformAccountsDataContextProvider>
            <GamingPlatformsDataContextProvider>
              <DataContextProvider>{props.children}</DataContextProvider>
            </GamingPlatformsDataContextProvider>
          </PlatformAccountsDataContextProvider>
        </GamesDataContextProvider>
      </RecordsDataContextProvider>
    </ScreenshotsDataContextProvider>
  );
};
