import * as React from "react";
import "./contentCard.scss";
import { Content } from "@models/index";
import * as moment from "moment";

export interface ContentCardProps {
  content: Content;
  selected: boolean;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export interface ContentCardState {}

export class ContentCard extends React.Component<
  ContentCardProps,
  ContentCardState
> {
  state = {};
  getImageUrl() {
    if (
      this.props.content.url.includes("youtube.com") ||
      this.props.content.url.includes("youtu.be")
    ) {
      var videoId = this.props.content.url
        .replace("https://youtu.be/", "")
        .replace("/", "");
      return "https://img.youtube.com/vi/" + videoId + "/mqdefault.jpg";
    } else {
      return this.props.content.url;
    }
  }
  render() {
    return (
      <div className="content-card-container">
        <div
          className="content-card"
          style={{
            backgroundImage: "url(" + this.getImageUrl() + ")"
          }}
          onClick={this.props.onClick}
        >
          <div className="content-details">
            <p>{this.props.content.title}</p>
            <p className="date">
              {moment(this.props.content.publicationDate).fromNow()}
            </p>
          </div>
        </div>
      </div>
    );
  }
}
