import * as React from "react";
import "./tabs.scss";

export interface LayoutTab {
  key: string;
  title: string;
  onClickCallback: (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    selectedKey: string
  ) => void;
}


export interface TabsProps {
  tabs: LayoutTab[];
  selectedKey: string;
}

export interface TabsState {}

export class Tabs extends React.Component<TabsProps, TabsState> {
  state = {};
  render() {
    return (
      <div className="tabsContainer d-flex justify-content-md-center">
        {this.props.tabs.map(tab => (
          <span
            className={
              "tab " +
              (tab.key === this.props.selectedKey
                ? "selectedTab"
                : "unselectedTab")
            }
            key={tab.key}
            style={{ width: "calc(100% / " + this.props.tabs.length + ")" }}
            onClick={e => tab.onClickCallback(e, tab.key)}
          >
            {tab.title}
          </span>
        ))}
      </div>
    );
  }
}
