import * as React from "react";
import { useState, useCallback } from "react";
import { IWrappableDataContext } from "../data.context";
import { PlatformAccount } from "@models/index";
import useApi from "@app/hooks/api.hook";

const PLATFORM_ACCOUNTS_LOCAL_STORAGE_KEY = "PLATFORM_ACCOUNTS";

export interface IPlatformAccountsDataContext extends IWrappableDataContext{
  getPlatformAccountsAsync: (forceRefresh: boolean) => Promise<PlatformAccount[]>;
}


export const PlatformAccountsDataContext = React.createContext<IPlatformAccountsDataContext>({
  clear: undefined,
  getPlatformAccountsAsync: undefined
});

const PlatformAccountsDataContextProvider: React.FC = props => {
  // States
  const [platformAccounts, setPlatformAccounts] = useState<PlatformAccount[]>(null);

  // Hooks
  const api = useApi();



  const getPlatformAccountsAsync = useCallback(async (forceRefresh: boolean) : Promise<PlatformAccount[]> => {
    if (platformAccounts == undefined || forceRefresh) {
      var platformAccounts_localStorage: PlatformAccount[] = JSON.parse(
        localStorage.getItem(PLATFORM_ACCOUNTS_LOCAL_STORAGE_KEY)
      );
      if (platformAccounts_localStorage !== null) {
        setPlatformAccounts(platformAccounts_localStorage);
        return platformAccounts_localStorage;
      } else {
        var fetchPlatformAccounts = await api.getPlatformAccountsAsync();
        setPlatformAccounts(fetchPlatformAccounts);
        return fetchPlatformAccounts;
      }
    } else
    {
      return platformAccounts;
    }
  }, [setPlatformAccounts, JSON.parse, localStorage.getItem, api.getPlatformAccountsAsync]);



  const clear = useCallback(() => {
    setPlatformAccounts(null);
  }, [setPlatformAccounts]);




  const PlatformAccountsContextValue: IPlatformAccountsDataContext = {
    clear,
    getPlatformAccountsAsync
  };

  return (
    <PlatformAccountsDataContext.Provider value={PlatformAccountsContextValue}>
      {props.children}
    </PlatformAccountsDataContext.Provider>
  );
};

export default PlatformAccountsDataContextProvider;
