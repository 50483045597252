import * as React from "react";
import "./action-button.component.scss";
import { PropTypes, Button, CircularProgress } from "@material-ui/core";

export interface IActionButtonProperties {
  text: string;
  color: PropTypes.Color;
  isLoading?: boolean;
  disabled?: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

export const ActionButton: React.FC<IActionButtonProperties> = (props) => {
  return (
    <Button 
      className="action-button"
      variant="contained"
      color={props.color}
      onClick={(e) => props.onClick(e)}
      disabled={props.disabled} 
      disableTouchRipple={props.isLoading}
    >
      {props.isLoading ? 
        <CircularProgress className="loading-indicator"/> 
        : 
        <span className="action-button-text">
          {props.text}
        </span>
      }
    </Button>
  );
};
