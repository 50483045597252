import * as React from "react";
import "./platform.component.scss";
import { Feed } from "@components/feed/feed.component";
import { useContext, useEffect, useState } from "react";
import { GamingPlatformsDataContext } from "@contexts/data/gamingPlatform.data";
import { PlatformAccountsDataContext } from "@app/contexts/data/platformAccount.data";
import { GamingPlatform, PlatformAccount } from "@models/index";
import { PlatformOverviewCard } from "./platformOverviewCard/platformOverviewCard.component";

export const PlatformPage = () => {
  const gamingPlatformsContext = useContext(GamingPlatformsDataContext);
  const platformAccountsContext = useContext(PlatformAccountsDataContext);

  const [gamingPlatforms, setGamingPlatforms] = useState<GamingPlatform[]>();
  const [platformAccounts, setplatformAccounts] = useState<PlatformAccount[]>();

  // When component mount for the first time, fetch gamingPlatforms and platformAccounts
  useEffect(() => {
    gamingPlatformsContext.getGamingPlatformsAsync(false).then(gamingPlatforms => setGamingPlatforms(gamingPlatforms));
    platformAccountsContext.getPlatformAccountsAsync(false).then(platformAccounts => setplatformAccounts(platformAccounts));
  }, []);

  return (
    <Feed>
        {gamingPlatforms?.map(gamingPlatform => 
          <PlatformOverviewCard
            key={gamingPlatform.id}
            gamingPlatform={gamingPlatform}
            platformAccount={platformAccounts?.find(
              platformAccount =>
                platformAccount.gamingPlatformId === gamingPlatform.id
            )}
            ></PlatformOverviewCard>
        )}
      </Feed>
  );
};
