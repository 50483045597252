import * as React from "react";
import { useState, useCallback } from "react";
import { IWrappableDataContext } from "../data.context";
import { Screenshot } from "@models/index";
import useApi from "@app/hooks/api.hook";

const SCREENSHOTS_LOCAL_STORAGE_KEY = "SCREENSHOTS";

export interface IScreenshotsDataContext extends IWrappableDataContext{
  getScreenshotsAsync: (forceRefresh: boolean) => Promise<Screenshot[]>;
  getScreenshotAsync: (screenshotId: number, forceRefresh: boolean) => Promise<Screenshot>;
}


export const ScreenshotsDataContext = React.createContext<IScreenshotsDataContext>({
  clear: undefined,
  getScreenshotsAsync: undefined,
  getScreenshotAsync: undefined
});

const ScreenshotsDataContextProvider: React.FC = props => {
  // States
  const [screenshots, setScreenshots] = useState<Screenshot[]>(null);

  // Hooks
  const api = useApi();



  const getScreenshotsAsync = useCallback(async (forceRefresh: boolean) : Promise<Screenshot[]> => {
    if (forceRefresh) setScreenshots(null);

    if (screenshots == undefined || forceRefresh) {
      var screenshots_localStorage: Screenshot[] = JSON.parse(
        localStorage.getItem(SCREENSHOTS_LOCAL_STORAGE_KEY)
      );
      if (screenshots_localStorage !== null) {
        setScreenshots(screenshots_localStorage);
        return screenshots_localStorage;
      } else {
        var fetchScreenshots = await api.getScreenshotsAsync();
        setScreenshots(fetchScreenshots);
        return fetchScreenshots;
      }
    } else
    {
      return screenshots;
    }
  }, [setScreenshots, JSON.parse, localStorage.getItem, api.getScreenshotsAsync]);




  const getScreenshotAsync = useCallback(async (screenshotId: number, forceRefresh: boolean) : Promise<Screenshot> => {
    if (forceRefresh) setScreenshots(null);

    if (screenshots == undefined || forceRefresh) {
      var screenshots_localStorage: Screenshot[] = JSON.parse(
        localStorage.getItem(SCREENSHOTS_LOCAL_STORAGE_KEY)
      );
      if (screenshots_localStorage !== null) {
        setScreenshots(screenshots_localStorage);
        return screenshots_localStorage.find(g => g.id == screenshotId);
      } else {
        var fetchScreenshot = await api.getScreenshotAsync(screenshotId);
        setScreenshots([...screenshots, fetchScreenshot]);
        return fetchScreenshot;
      }
    }
    else
    {
      return screenshots.find(g => g.id == screenshotId);
    }
  }, [setScreenshots, JSON.parse, localStorage.getItem, api.getScreenshotAsync]);




  const clear = useCallback(() => {
    setScreenshots(null);
  }, [setScreenshots]);




  const ScreenshotsContextValue: IScreenshotsDataContext = {
    clear,
    getScreenshotsAsync,
    getScreenshotAsync
  };

  return (
    <ScreenshotsDataContext.Provider value={ScreenshotsContextValue}>
      {props.children}
    </ScreenshotsDataContext.Provider>
  );
};

export default ScreenshotsDataContextProvider;
