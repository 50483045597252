import { createAuthContext, IAuthContext } from '@haberkorn/react-auth';
import { UiLanguage, ApiUser_Me } from "@models/index";
import { useContext } from 'react';

export const DEFAULT_LANGUAGE_ISO2: string = "FR";
export const UI_LANGUAGES: UiLanguage[] = [
  {
    iso2: "FR",
    flag: "assets/flags/fr.svg",
    title: "Français"
  },
  {
    iso2: "DE",
    flag: "assets/flags/de.svg",
    title: "Deutsch"
  },
  {
    iso2: "EN",
    flag: "assets/flags/gb.svg",
    title: "English"
  }
];

export const enum RootUrlNames {
  BACKEND_API = "BACKEND_API_ROOT_URL"
}

export class ConfigHelper {
  constructor() {}

  private static authContext: React.Context<IAuthContext<ApiUser_Me>> = undefined;

  public getApiRootURL(): string {
    return process.env.BACKEND_API_ROOT_URL;
  }

  public getDefaultLanguageIso2(): string {
    return DEFAULT_LANGUAGE_ISO2;
  }

  public getDefaultLanguage(): UiLanguage {
    return (
      UI_LANGUAGES.find(l => l.iso2 === DEFAULT_LANGUAGE_ISO2) ||
      UI_LANGUAGES[0]
    );
  }

  public getUiLanguages(): UiLanguage[] {
    return UI_LANGUAGES;
  }

  public static getRootUrl(rootUrl: RootUrlNames): string {
    switch (rootUrl) {
      case RootUrlNames.BACKEND_API:
        return process.env.BACKEND_API_ROOT_URL;
      default:
        throw new Error("The requested root url doesn't exists");
    }
  }

  public static getEnvironmentVariable(environmentVariableName: string): string {
    return process.env[environmentVariableName];
  }

  public static useAuthContext()
  {
    if(!ConfigHelper.authContext)
    {
      ConfigHelper.authContext = createAuthContext<ApiUser_Me>();
    }

    return useContext(ConfigHelper.authContext);
  }

  public static getAuthProvider()
  {
    if(!ConfigHelper.authContext)
    {
      ConfigHelper.authContext = createAuthContext<ApiUser_Me>();
    }

    return ConfigHelper.authContext.Provider;
  }
}
