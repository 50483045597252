import * as React from "react";
import "./horizontalScroll.scss";
import ScrollMenu from "react-horizontal-scrolling-menu";
import { Content } from "@models/index";
import { ContentCard } from "../contentCard/contentCard.component";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";

export interface HorizontalScrollProps {
  contents: Content[];
  selected: string;
  onElementSelect: (selectedItemKey: string | number | null) => void;
}

export interface HorizontalScrollState {}

export class HorizontalScroll extends React.Component<
  HorizontalScrollProps,
  HorizontalScrollState
> {
  state = {};
  render() {
    var menuItems = this.renderMenuItems();

    return (
      <ScrollMenu
        data={menuItems}
        arrowLeft={this.renderArrowLeft()}
        arrowRight={this.renderArrowRight()}
        selected={this.props.selected}
        onSelect={this.props.onElementSelect}
      />
    );
  }

  renderMenuItems(): JSX.Element[] {
    return this.props.contents?.map(c => (
      <ContentCard
        key={c.id}
        content={c}
        selected={this.props.selected === c.id.toString()}
      />
    ));
  }

  renderArrowLeft(): JSX.Element {
    return <ChevronLeft style={{ fontSize: 50 }} />;
  }

  renderArrowRight(): JSX.Element {
    return <ChevronRight style={{ fontSize: 50 }} />;
  }
}
