import * as React from "react";
import { CircularProgress } from "@material-ui/core";
import "./preparingSession.component.scss";
import { PublicCardLayout } from "@app/components";

export const PreparingSessionPage = () => {
  return (
    <div id="preparing-session-page">
      <PublicCardLayout
        title="Preparing your session..."
        additionalContent={<CircularProgress/>}
      />
    </div>
  );
};
