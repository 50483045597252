import { useCallback } from 'react';
import { request, RequestMethods } from '@haberkorn/react-requests';
import { ConfigHelper, RootUrlNames } from '@app/helpers/config.helper';
import { Record, Game, GamingPlatform, PlatformAccount, Screenshot } from '@models/index';

const useApi = () => {

  return {

    //**************
    // Records
    //**************
    getRecordsAsync: useCallback(() => {
      return request<Record[], null>(RequestMethods.GET, ConfigHelper.getRootUrl(RootUrlNames.BACKEND_API), 'v1/Record');
    }, [request, ConfigHelper.getRootUrl]),

    getRecordAsync: useCallback((recordId: number) => {
      return request<Record, null>(RequestMethods.GET, ConfigHelper.getRootUrl(RootUrlNames.BACKEND_API), `v1/Record/${recordId}`);
    }, [request, ConfigHelper.getRootUrl]),

    
    //**************
    // Screenshots
    //**************
    getScreenshotsAsync: useCallback(() => {
      return request<Screenshot[], null>(RequestMethods.GET, ConfigHelper.getRootUrl(RootUrlNames.BACKEND_API), 'v1/Screenshot');
    }, [request, ConfigHelper.getRootUrl]),

    getScreenshotAsync: useCallback((screenshotId: number) => {
      return request<Screenshot, null>(RequestMethods.GET, ConfigHelper.getRootUrl(RootUrlNames.BACKEND_API), `v1/Screenshot/${screenshotId}`);
    }, [request, ConfigHelper.getRootUrl]),

    
    //**************
    // Gaming Platforms
    //**************
    getGamingPlatformsAsync: useCallback(() => {
      return request<GamingPlatform[], null>(RequestMethods.GET, ConfigHelper.getRootUrl(RootUrlNames.BACKEND_API), 'v1/GamingPlatform');
    }, [request, ConfigHelper.getRootUrl]),

    getPlatformAccountsAsync: useCallback(() => {
      return request<PlatformAccount[], null>(RequestMethods.GET, ConfigHelper.getRootUrl(RootUrlNames.BACKEND_API), 'v1/PlatformAccount');
    }, [request, ConfigHelper.getRootUrl]),


    //**************
    // Games
    //**************
    getGamesAsync: useCallback(() => {
      return request<Game[], null>(RequestMethods.GET, ConfigHelper.getRootUrl(RootUrlNames.BACKEND_API), 'v1/Game');
    }, [request, ConfigHelper.getRootUrl]),

    getGameAsync: useCallback((gameId: number) => {
      return request<Game, null>(RequestMethods.GET, ConfigHelper.getRootUrl(RootUrlNames.BACKEND_API), `v1/Game/${gameId}`);
    }, [request, ConfigHelper.getRootUrl]),
  }
};

export default useApi;
