import * as React from "react";
import "./public-card.layout.component.scss";
import Button from '@material-ui/core/Button';
import { CircularProgress } from "@material-ui/core";
import { IActionButtonProperties } from "@app/components";
import { ActionButton } from "@components/index";

export interface IPublicCardLayoutProps{
  title: string;
  subtitle?: string;
  additionalContent?: React.ReactNode;
  actions?: IActionButtonProperties[]
  postActionsContent?: React.ReactNode;
  languageSwitch?: boolean;
  footer?: boolean;
  iconPath?: string;
}

export const PublicCardLayout: React.FC<IPublicCardLayoutProps> = (props) => {
  return (
    <div id="public-card-layout-container">
      <div id="public-card">
        <img id="public-card-image-logo" src={props.iconPath || "/assets/images/logo.jpg"}></img>
        <div id="public-card-tile">{props.title}</div>

        {/* SUBTITLE */}
        {props.subtitle && <div id="public-card-subtitle">{props.subtitle}</div>}

        {/* ADDITIONAL CONTENT */}
        {props.additionalContent && <div id="public-card-additional-content">{props.additionalContent}</div>}

        {/* ACTIONS */}
        {props.actions && props.actions.length > 0 &&
          <div id="public-card-content-container">
            {props.actions.map((action, index) => 
              <ActionButton 
                key={index}
                text={action.text}
                onClick={action.onClick}
                color={action.color} 
                disabled={action.disabled}
                isLoading={action.isLoading}
              >
              </ActionButton>
            )}
          </div>
        }

        {/* POST ACTIONS */}
        {props.postActionsContent && <div id="public-card-post-actions-content">{props.postActionsContent}</div>}

        {/* LANGUAGE SWITCH */}
        {props.languageSwitch && <div id="public-card-language-switch">{/* TODO */}</div>}

        {/* FOOTER */}
        {props.footer && <div id="public-card-footer">{/* TODO */}</div>}
      </div>
    </div>
  );
};
