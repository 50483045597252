import * as React from "react";
import "./me.component.scss";

export const MePage = () => {
  return (
    <div id="mePage">
      Me
    </div>
  );
};
