import * as React from "react";
import { Redirect, Route } from "react-router";
import { useLocation, RouteProps } from "react-router-dom";
import { ConfigHelper } from "@app/helpers";

import { LOGIN_PATH, ACCESS_RESTRICTED_PATH } from "./routing";

export const QUERY_PARAMETER_REDIRECT_PATH = "redirect_path";

export interface ProtectedRouteProps extends RouteProps {
  mustBeAuthenticated: boolean;
  mustBeAdmin: boolean;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = props => {
  const authContext = ConfigHelper.useAuthContext();
  const location = useLocation();

  let redirectPath: string = undefined;
  let needRedirectUrlParam: boolean = false;

  // Check if authenticated
  if (props.mustBeAuthenticated && !authContext.isAuthenticated) {
    redirectPath = LOGIN_PATH;
    needRedirectUrlParam = true;
  }

  // Check if admin
  if (!redirectPath && props.mustBeAdmin && authContext.isAuthenticated) {
    redirectPath = ACCESS_RESTRICTED_PATH;
  }

  if (redirectPath) {
    if (needRedirectUrlParam) {
      var callbackRedirect = encodeURIComponent(`${location.pathname}${location.search}`);
      redirectPath = `${redirectPath}?${QUERY_PARAMETER_REDIRECT_PATH}=${callbackRedirect}`;
    }

    const renderComponent = () => <Redirect to={redirectPath} />;
    return <Route {...props} component={renderComponent} render={undefined} />;
  } else {
    return <Route {...props} />;
  }
};

export default ProtectedRoute;
