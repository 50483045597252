import * as React from "react";
import { useState, useCallback } from "react";
import { IWrappableDataContext } from "../data.context";
import { Record } from "@models/index";
import useApi from "@app/hooks/api.hook";

const RECORDS_LOCAL_STORAGE_KEY = "RECORDS";

export interface IRecordsDataContext extends IWrappableDataContext{
  getRecordsAsync: (forceRefresh: boolean) => Promise<Record[]>;
  getRecordAsync: (recordId: number, forceRefresh: boolean) => Promise<Record>;
}


export const RecordsDataContext = React.createContext<IRecordsDataContext>({
  clear: undefined,
  getRecordsAsync: undefined,
  getRecordAsync: undefined
});

const RecordsDataContextProvider: React.FC = props => {
  // States
  const [records, setRecords] = useState<Record[]>(null);

  // Hooks
  const api = useApi();



  const getRecordsAsync = useCallback(async (forceRefresh: boolean) : Promise<Record[]> => {
    if (forceRefresh) setRecords(null);

    if (records == undefined || forceRefresh) {
      var records_localStorage: Record[] = JSON.parse(
        localStorage.getItem(RECORDS_LOCAL_STORAGE_KEY)
      );
      if (records_localStorage !== null) {
        setRecords(records_localStorage);
        return records_localStorage;
      } else {
        var fetchRecords = await api.getRecordsAsync();
        setRecords(fetchRecords);
        return fetchRecords;
      }
    } else
    {
      return records;
    }
  }, [setRecords, JSON.parse, localStorage.getItem, api.getRecordsAsync]);




  const getRecordAsync = useCallback(async (recordId: number, forceRefresh: boolean) : Promise<Record> => {
    if (forceRefresh) setRecords(null);

    if (records == undefined || forceRefresh) {
      var records_localStorage: Record[] = JSON.parse(
        localStorage.getItem(RECORDS_LOCAL_STORAGE_KEY)
      );
      if (records_localStorage !== null) {
        setRecords(records_localStorage);
        return records_localStorage.find(g => g.id == recordId);
      } else {
        var fetchRecord = await api.getRecordAsync(recordId);
        setRecords([...records, fetchRecord]);
        return fetchRecord;
      }
    }
    else
    {
      return records.find(g => g.id == recordId);
    }
  }, [setRecords, JSON.parse, localStorage.getItem, api.getRecordAsync]);




  const clear = useCallback(() => {
    setRecords(null);
  }, [setRecords]);




  const RecordsContextValue: IRecordsDataContext = {
    clear,
    getRecordsAsync,
    getRecordAsync
  };

  return (
    <RecordsDataContext.Provider value={RecordsContextValue}>
      {props.children}
    </RecordsDataContext.Provider>
  );
};

export default RecordsDataContextProvider;
