import * as React from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import "./loginCallback.component.scss";
import { PublicCardLayout } from "@app/components";
import { ConfigHelper } from '@app/helpers';
import { QUERY_PARAMETER_REDIRECT_PATH } from "@app/routing/protectedRoute";

export const LoginCallbackPage = () => {

  const location = useLocation();
  const authContext = ConfigHelper.useAuthContext();
  const [triedToLogin, setTriedToLogin] = React.useState<boolean>(false);
  const [tryToRegister, setTryToRegister] = React.useState<boolean>(false);

  useEffect(() => {
    let params = new URLSearchParams(location.search);
    var authorizationCode = params.get('authorization_code');
    authContext.login(authorizationCode, params, authContext.defaultOnSessionReadyCallback(QUERY_PARAMETER_REDIRECT_PATH));
    setTriedToLogin(true);
  }, []);

  useEffect(() => {
    if(!authContext.isWorking && triedToLogin && !tryToRegister)
    {
      let params = new URLSearchParams(location.search);
      var authorizationCode = params.get('authorization_code');
      authContext.register(authorizationCode, params, authContext.defaultOnSessionReadyCallback(QUERY_PARAMETER_REDIRECT_PATH));
      setTryToRegister(true);
    }
  }, [authContext.isWorking]);

  return (
    <div id="loging-callback-page">
      <PublicCardLayout
        title="Logging you in..."
        iconPath="/assets/images/viking.png"
        additionalContent={<CircularProgress/> }
      />
    </div>
  );
};
