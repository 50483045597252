import * as React from "react";
import { useCallback, useContext } from "react";
import { GamingPlatformsDataContext } from "./data/gamingPlatform.data";
import { PlatformAccountsDataContext } from "./data/platformAccount.data";

// Interface defining all needed methods to be compliant with the general data actions
export interface IWrappableDataContext {
  clear: () => void;
}

export interface IDataState {}

export interface IDataContext extends IDataState {
  clearAll: () => void;
}

const initialState: IDataState = {};

export const DataContext = React.createContext<IDataContext>({
  ...initialState,
  clearAll: () => {}
});

/**
 * This context should only be used for global data actions like clear all
 * To access or modify a single data type,
 * @param props
 */
const DataContextProvider: React.FC = props => {
  // Context declaration
  const { clear: clearGamingPlatforms } = useContext(GamingPlatformsDataContext);
  const { clear: clearPlatformAccounts } = useContext(PlatformAccountsDataContext);

  const clearAll = useCallback(() => {
    clearGamingPlatforms();
    clearPlatformAccounts();
  }, [clearGamingPlatforms, clearPlatformAccounts]);

  const contextValue: IDataContext = { clearAll };

  return (
    <DataContext.Provider value={contextValue}>
      {props.children}
    </DataContext.Provider>
  );
};

export default DataContextProvider;
