import * as React from "react";
import "./feed.scss";

export interface FeedProps {}

export interface FeedState {}

export class Feed extends React.Component<FeedProps, FeedState> {
  state = {};
  render() {
    return (
      <div id="feedContainer" className="d-flex justify-content-md-center">
        <div id="feed">{this.props.children}</div>
      </div>
    );
  }
}
