import "./Screenshot.scss";
import * as React from "react";
import { Feed } from "@components/index";
import { useLocation } from "react-router-dom";
import { Game, Screenshot } from "@models/index";
import { useState, useEffect } from "react";
import useApi from "@app/hooks/api.hook";

export const ScreenshotPage = () => {
  const location = useLocation();
  const api = useApi();

  const [screenshot, setScreenshot] = useState<Screenshot>(undefined);
  const [game, setGame] = useState<Game>(undefined);

  useEffect(() => {
    var screenshotId = "";
    var path = location.pathname;

    if (path.startsWith("/screenshots/")) {
      screenshotId = path.replace("/screenshots/", "");
    }

    async function getData() {
      var screenshot = await api.getScreenshotAsync(parseInt(screenshotId));
      var game = await api.getGameAsync(screenshot.gameId);

      setScreenshot(screenshot);
      setGame(game);
    }

    getData();
  }, [location.pathname, api.getScreenshotAsync, api.getGameAsync]);

  return (
    <Feed>
      {screenshot ? (
        <React.Fragment>
          <div className="screenshot-title">
            {screenshot.title}
          </div>
          <img
            className="screenshot"
            src={screenshot.url}
            alt={screenshot.title}
          />
        </React.Fragment>
      ) : null}
    </Feed>
  );
}
