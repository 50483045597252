import * as React from "react";
import "./home.component.scss";

export const HomePage = () => {
  return (
    <div id="homePage">
      <div id="homeContainer">
        <div id="homeCard">
          <img id="homeHeaderLogo" src="/assets/images/logo_white.png" alt="Home logo" />
        </div>
      </div>
      <video
        autoPlay
        muted
        loop
        poster="/assets/images/home_background.jpg"
        id="homeBackground"
      >
        <source src="/assets/images/home_background.mp4" type="video/mp4" />
      </video>
    </div>
  );
};
