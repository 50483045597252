import * as React from "react";
import "./login.component.scss";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { PublicCardLayout } from "@app/components";
import { CircularProgress } from "@material-ui/core";

export const LoginPage = () => {

  const location = useLocation();

  useEffect(() => {
    document.location.href = `${process.env.ENVIRONMENT == 'local' ? 'http://localhost:8081' : 'https://account.sneakyearth.com'}/oauth/authorize?client_id=83CC64C7-86CD-4893-B44D-D8703BBF196C&response_type=code&state=5ca75bd30&scope=user%2Fidentity&redirect_url=${encodeURIComponent(`${window.location.protocol}//${window.location.host}/auth/callback/login${location.search ? '?'+location.search : ''}`)}`;
  }, []);

  return (
    <div id="login-page">
      <PublicCardLayout
        title="Redirecting..."
        additionalContent={<CircularProgress/> }
      />
    </div>
  );
};
