import * as React from "react";
import "./logout.component.scss";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { ConfigHelper } from "@app/helpers";
import { PublicCardLayout } from "@app/components";
import { CircularProgress } from "@material-ui/core";

export const LogoutPage = () => {
  const history = useHistory();
  const authContext = ConfigHelper.useAuthContext();

  useEffect(() => {
    if(authContext.isAuthenticated)
      authContext.logout();
    else
      history.push('/');
  }, [authContext.isAuthenticated]);

  return (
    <div id="logout-page">
      <PublicCardLayout
        title="Logging you out..."
        additionalContent={<CircularProgress/> }
      />
    </div>
  );
};
